// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//= require jquery
//= require moment 
//= require jquery_ujs
//= require turbolinks
//= require cocoon
//= require_tree .

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('datatables.net-bs4')
require('select2')
require('summernote')
require("packs/app")
require("packs/bootstrap-datepicker.min")
require("packs/bootstrap-timepicker.min")
require("packs/bootstrap.min")
require("@nathanvda/cocoon")
import $ from 'jquery';
global.$ = jQuery;



